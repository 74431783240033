/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Gotham Rounded Light';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Rounded Light'), url('./assets/fonts/Gotham Rounded Light.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Rounded Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Rounded Medium'),
    url('./assets/fonts/Gotham Rounded Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Rounded Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Rounded Bold'), url('./assets/fonts/Gotham Rounded Bold.woff') format('woff');
}

@font-face {
  font-family: 'Heavy Equipment';
  font-style: normal;
  font-weight: normal;
  src: local('Heavy Equipment'), url('./assets/fonts/Heavy Equipment.ttf') format('tff');
}

/* Small devices only (landscape phones, 576px and down) */
@media (max-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

body {
  margin: 0;
  padding: 0;
  color: #1e272c;
  background-color: #f2f6f9 !important;
  font-family: 'Gotham Rounded Light', 'Helvetica', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
